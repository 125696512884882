.nav-bar{
    position: static;
    z-index: 999;
}
.container {
    /* border: 5px solid #4F284A;
    background-color: #4F284A; */
    border: 5px solid #3f203b;
    background-color: #3f203b;
    display: flex;
    text-decoration: none;
}

.container > *{
    text-decoration: none;
    color: #fff;
    font-family: 'Days One', sans-serif;
    font-weight: 5rem;
    padding: 10px;
    text-align: center;
    align-self: center;
    /* font-family: 'Catamaran', sans-serif; */
}
.container > *:hover{
    color: #674a63;
}

.resumeActive {
    padding-right: 20rem;
    color: yellow;
}

.active { 
    color: yellow;
}

.inactive {
    color: #fff;
}

.resumeInactive {
    padding-right: 20rem;
}

.name {
    margin-right: auto;
    padding-left: 20rem;
    font-size: 2rem;
    color: #fff;
}

.socials {
    display: flex;
    /* border: 5px solid #815c7c;
    background-color: #815c7c; */
    background-color: #674a63;
    border: 5px solid #674a63;
    text-decoration: none;
    justify-content: flex-end;
    margin-bottom: auto;
    letter-spacing: 10px;
    padding-right: 20rem;
}

.socials > * {
    cursor: pointer;
    color: #fff;
    font-size: 3rem;
    text-align: center;
    align-self: center;
}

.socials > *:hover {
    color: #3f203b;
}

.socials > *:active {
    color: yellow;
}

.active:hover {
    color: yellow;
}

.resumeActive:hover {
    color: yellow;
}

.name:hover {
    color: #fff;
}

.menu-icon {
    width: 40px;
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
    color: #fff;
    background-color: #674a63;
    border-radius: 3px;
    padding: 10px;
    display: none;
    z-index: 999;
  }
  
  .menu-icon__line {
    width: 100%;
    height: 5px;
    background-color: #fff;
    transition: transform 0.2s ease-in-out;
    border-radius: 3px;
  }
  
  .menu-icon--open .menu-icon__line:nth-of-type(1) {
    transform: translateY(10px) rotate(585deg);
  }
  
  .menu-icon--open .menu-icon__line:nth-of-type(2) {
    opacity: 0;
  }
  
  .menu-icon--open .menu-icon__line:nth-of-type(3) {
    transform: translateY(-10px) rotate(-585deg);
  }

  .showSubNav {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #3f203b;
    right: 0;
    left: 0;
    text-align: left;
    padding: 16px 0;
    /* margin-top: 19rem; */
    padding-left: 20px;
    padding-right: 30px;
    font-family: 'Days One', sans-serif;
    z-index: 999;
  }

.socialsSub {
    padding-left: 8px;
}

.socialsSub > * {
    cursor: pointer;
    color: #fff;
    font-size: 3rem;
    text-align: center;
    align-self: center;
    padding-right: 10px;
}

.socialsSub > *:hover {
    color: #674a63;
}

.socialsSub > *:active {
    color: yellow;
}

@media (max-width: 1360px) {
    .menu-icon {
        display: flex;
        /* margin-left: -100%; */
        margin-right: 15%;
        margin-right: 0rem;
        margin-left: 0%;
        left: 0;
        right: 0;
    }

    .fornav {
        padding-top: 155px;
    }

    .fornav-home {
        padding-top: 7rem;
    }

    .nav-bar {
        position:absolute;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
    }

    .active, .inactive, .resumeActive, .resumeInactive {
        display: none;
    }

    .socials {
        visibility: hidden;
    }

    .name {
        padding-left: 1rem;
    }

    .showSubNav {
        display: visible;
    }
}

@media (min-width: 1361px) {
    .menu-icon {
        display: none;
    }

    .active, .inactive, .resumeActive, .resumeInactive {
        display: visible;
    }

    .socials {
        visibility: visible;
    }

    .showSubNav {
        display: none;
    }
}

.showSubNav {
    display: visible;
}
  
.hideSubNav {
    display: none;
}

.activeSub:hover {
    color: yellow;
}

.resumeActiveSub:hover {
    color: yellow;
}

.activeSub {
    text-decoration: none;
    color: yellow;
}

.inactiveSub {
    text-decoration: none;
    color: #fff;
}

.resumeActiveSub {
    text-decoration: none;
    color: yellow;
}

.resumeInactiveSub {
    text-decoration: none;
    color: #fff;
}

.inactiveSub:hover {
    color: #674a63;
}

.resumeInactiveSub:hover {
    color: #674a63;
}

.activeSub, .inactiveSub, .resumeActiveSub, .resumeInactiveSub {
    padding: 0.5rem;
    font-size: 1.2rem
}

@media (max-width: 320px) { 

    .name {
        font-size: 1.6rem;
    }

    .activeSub, .inactiveSub, .resumeActiveSub, .resumeInactiveSub {
        font-size: 1rem
    }
}

.showSubNav {
    border: 1px #674a63 solid;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
}

body {
    overflow: hidden;
    overflow-x: hidden;
}