.resume-container {
    margin-top: 1.2rem;
    display: flex;
    justify-content: center;
    text-align: center;
    align-self: center;
    padding-bottom: 80px;
}

.resume-ss {
    width: 40rem;
}

@media (max-width: 1000px) {
    .resume-ss{
        width: 90%
    }
}