.pic-container > *{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
}

.profile-pic {
    margin-top: 1.2rem;
    width: 40rem;
    border-style: none;
    border-radius: 20px;
}

.title-name {
    color: #fff;
    font-family: 'Catamaran', sans-serif;
    font-size: 6.5em;
    padding-top: 0;
    margin-top: 0;
    display: flex;
    flex-wrap: wrap;
}

.title-me {
    color: #fff;
    font-family: 'Catamaran', sans-serif;
    font-size: 2rem;
}

.special {
    margin-top: -7rem;
    margin-bottom: -2rem;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items: center;
}

.block1 {
    background-color: #fff;
    width: 160px;
    height: 10px;
}

.block {
    background-color: #fff;
    width: 160px;
    height: 20px;
}

.school {
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items: center;
}

.tiger {
    width: 75px;
    padding: 0;
    margin-right: -20px;
    margin-left: -20px;
}

.slide{
    animation-duration: 500ms;
    animation-name: fadein, slidein;
}

@keyframes slidein {
    from {
      margin-left: -40%;
      width: 30%;
    }
  
    to {
      margin-right: 0%;
      width: 40%;
    }
  }

  @keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
}

@media (max-width: 1000px) {
    .profile-pic{
        width: 75%
    }

    .special {
        margin-top: -4rem;
    }
}

@media (max-width: 750px){
    .title-name{
        font-size: 4rem;
        font-size: 300%;
    }

    .block, .block1 {
        width: 6rem;
    }

    .title-me {
        font-size: 1.3rem;
        font-size: 110%;
    }

    .pic-container {
        padding-top: 10%;
    }
    
}

@media (max-width: 320px) {
    .title-name{
        font-size: 270%;
    }

    .title-me {
        font-size: 1.3rem;
        font-size: 90%;
    }
}