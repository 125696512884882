.contact-container {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
    /* margin-top: 30px;
    margin-left: 25%;
    margin-right: 25%; */
}

.name-box {
    text-decoration: none;
    outline: none;
    border: none;
    font-family: 'Days One', sans-serif;
    width: 600px;
    /* height: 50px; */
    font-size: 1.5rem;
    background-color: transparent;
    border-left: #3f203b 5px solid;
    color: #fff;
    padding-left: 8px;
}

.msg-box {
    text-decoration: none;
    outline: none;
    border: none;
    font-family: 'Days One', sans-serif;
    resize: none;
    width: 600px;
    height: 200px;
    font-size: 1.5rem;
    background-color: transparent;
    border-left: #3f203b 5px solid;
    color: #fff;
    padding-left: 8px;
}

.validInput {
    text-decoration: none;
    outline: none;
    border: none;
    color: #fff;
    background-color: #3f203b;
    font-family: 'Days One', sans-serif;
    cursor: pointer;
    padding: 25px;
    margin-right: auto;
    justify-content: flex-end;
    font-size: 1rem;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    animation: none;
}

.validInput:hover {
    color: #3f203b;
    background-color: yellow;
}

label {
    display:block;
    font-family: 'Days One', sans-serif;
    color: #fff;
    padding-bottom: 5px;
    margin-left: auto;
}

.added-space {
    padding-bottom: 350px;
}

.inputIsThere {
    animation: slide-down 100ms forwards;
  }

  @keyframes slide-down {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
}

.inputIsNotThere {
    animation: slide-up .65s forwards;
  }
  
  @keyframes slide-up {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-100%);
      opacity: 0;
    }
  }

.noAni {
    animation: none;
}

.inputInvalid {
    text-decoration: none;
    outline: none;
    border: none;
    color: #fff;
    background-color: #ff4343;
    font-family: 'Days One', sans-serif;
    cursor: pointer;
    padding: 25px;
    margin-right: auto;
    justify-content: flex-end;
    font-size: 1rem;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    cursor: not-allowed;
    animation: vibrate 0.2s ease-in-out infinite;
  }
  
  @keyframes vibrate {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(5px);
    }
    75% {
      transform: translateX(-5px);
    }
    100% {
      transform: translateX(0);
    }
  }

.all-contact-container {
    padding-top: 60px;
}

.successButton {
    text-decoration: none;
    outline: none;
    border: none;
    color: black;
    background-color: #47e962;
    font-family: 'Days One', sans-serif;
    cursor: pointer;
    padding: 25px;
    margin-right: auto;
    justify-content: flex-end;
    font-size: 1rem;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    animation: vibrateUp 1s ease-in-out infinite;
}

@keyframes vibrateUp {
    0% {
      transform: translatey(0);
    }
    25% {
      transform: translateY(-5px);
    }
    50% {
      transform: translateY(5px);
    }
    75% {
      transform: translateY(-5px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @media (max-width: 640px) { 
    .name-box, .msg-box {
      width: 330px;
      width: 95%;
    }
  }