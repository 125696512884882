.footer {
    margin-top: 3rem;
    display: flex;
    border: 5px solid #3f203b;
    background-color: #3f203b;
    justify-content: center;
    font-family: 'Catamaran', sans-serif;
    color: #fff;
    position:relative;
    bottom: 0;
    margin-top: 200px;
}