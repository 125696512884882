html {
  scroll-behavior: smooth;
}

html, body {
  /* background-color: #815c7c; */
  background-color: #674a63;
  /* height: 100%; */
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 0;
  padding: 0;
  margin-bottom: 0;
  overflow: auto;
  overflow-x: hidden;
}

.cursor {
    position: absolute;
    width: 20rem;
    height: 20rem;
    border-radius: 50%;
    border: 2px solid yellow;
}

.cursor {
  position: absolute;
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  border: 2px solid yellow;
}

*{
  animation: loadingtransition 200ms ease-in;
}

@keyframes  loadingtransition{

  from{
      opacity: 0;
      transform: rotateY(-50deg);
  }
  to{
      opacity: 1;
      transform: rotateY(0);
  }
}

.cursor {
  animation: none;
}