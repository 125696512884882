.port-box {
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
/* margin-top: 30px;
    margin-left: 25%;
    margin-right: 25%; */
}

.port-container-grid {
    margin-top: 80px;
    display: grid;
    grid-template-columns: 350px 350px 350px;
    grid-template-rows: 250px 250px;
    grid-gap: 35px;
}

.port-container-grid > div {
    box-sizing: border-box;
    background-color: rgb(153, 153, 153);
    height: 100%;
    height: 250px;
    padding: 10px;
    margin: 0px;
    transition: transform 0.3s ease;
    box-shadow: 0 10px 20px rgba(0,0,0,0.25), 0 6px 6px rgba(0,0,0,0.29);
}

.Fithappens {
    background-image: url(
        "../pictures/fithappens.JPG"
    );
    background-size: cover;
    background-position: center;
    cursor: pointer;
    color: #fff;
    font-family: 'Catamaran', sans-serif;
}

.Fithappens:hover, .WordJustWord:hover, .Portfolio:hover {
    transform: translateY(-10px);
}

.WordJustWord {
    background-image: url(
        "../pictures/w.png"
    );
    background-size: cover;
    background-position: center;
    cursor: pointer;
    color: #fff;
    font-family: 'Catamaran', sans-serif;
}

.Portfolio {
    background-image: url(
        "../pictures/port.JPG"
    );
    background-size: cover;
    background-position: center;
    cursor: pointer;
    color: #fff;
    font-family: 'Catamaran', sans-serif;
}

.fit-button {
    color: #3f203b;
    font-family: 'Catamaran', sans-serif;
    cursor: pointer;
    background-color: #fff;
    border: none;
    outline: none;
    font-weight: bold;
}

.fit-button:hover {
    background-color: rgb(139, 139, 139);
}

@media (max-width: 1145px) { 
    .port-container-grid {
        grid-template-columns: auto;
        grid-template-rows: auto;
    }

    .space {
        margin-bottom: 80px;
    }

    .port-container-grid > div {
        width: 350px;
    } 
}